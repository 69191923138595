
























import _ from 'lodash';
import { GatewayHealthQueryStringMixin } from '@/features/core/components/mixins/gateway-health-query-string';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { GatewayHealth } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import { AppMaintenanceAppGatewaysViewQuery } from './__generated__/AppMaintenanceAppGatewaysViewQuery';
import {
  AppMaintenanceAppGatewaysViewGatewaysQuery,
  AppMaintenanceAppGatewaysViewGatewaysQueryVariables,
} from './__generated__/AppMaintenanceAppGatewaysViewGatewaysQuery';
import query from './view.gql';
import gatewaysQuery from './gateways.gql';

@Component({
  apollo: {
    healths: {
      query,
      pollInterval: 60000,
    },
    gateways: {
      query: gatewaysQuery,
      variables(this: GatewaysView): AppMaintenanceAppGatewaysViewGatewaysQueryVariables {
        return {
          take: this.take,
          skip: this.skip,
          healths:
            this.health === undefined
              ? [GatewayHealth.UNKNOWN, GatewayHealth.CRITICAL, GatewayHealth.DEGRADED]
              : [this.health],
          searchTerm: this.searchTerm,
        };
      },
      pollInterval: 60000,
    },
  },
  data() {
    return { healths: undefined, gateways: undefined };
  },
})
export default class GatewaysView extends Mixins(GatewayHealthQueryStringMixin, PaginationQueryStringMixin) {
  private readonly healths?: AppMaintenanceAppGatewaysViewQuery['healths'];
  private readonly gateways?: AppMaintenanceAppGatewaysViewGatewaysQuery['gateways'];

  protected get count(): number {
    return this.gateways?.count ?? 0;
  }

  protected get searchTerm(): string | undefined {
    return (this.$route.query.searchTerm as string) ?? undefined;
  }

  protected set searchTerm(searchTerm: string | undefined) {
    this.debouncedUpdateSearch(searchTerm);
  }

  // Replace the current route with the updated query object
  private debouncedUpdateSearch = _.debounce((searchTerm: string | undefined) => {
    const query = { ...this.$route.query }; // Make a copy of the current query object

    if (!searchTerm || searchTerm.length < 3) {
      delete query.searchTerm; // Remove the searchTerm from the query object
    } else {
      query.searchTerm = searchTerm; // Update the searchTerm in the query object
    }

    // Replace the current route with the updated query object
    void this.$router.replace({ query });
  }, 2000); // 2s delay
}
